<template>
    <v-dialog v-if="show" v-model="show" persistent max-width="400">
        <v-card class="custom_card status_dialog">
            <v-card-title :class="color">
                <span class="headline" style="color: white">{{header}}</span>
            </v-card-title>
            <v-card-text class="mt-1">
                <v-form autocomplete="off" class="white_bg_form">
                    <v-row dense>
                        <v-col cols="12">
                            <div class="text-center">{{text}}</div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center pt-5">
                <v-btn color="primary" class="close_button" dark @click="close">
                    {{$t('general.no')}}
                </v-btn>
                <v-btn color="primary" class="confirm_button" dark :loading="is_loading" @click="confirm">
                    {{$t('general.yes')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script type="text/babel">
    export default {
        props: ['show', 'header', 'text', 'color', 'is_loading'],
        methods: {
            confirm() {
                this.$emit('confirm');
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>